<template>
    <div class="relative flex flex-1 gap-2 sm:gap-4 items-center">
        <div
            v-if="searchModule"
            class="flex items-center rounded text-white px-2 py-1 font-bold"
            :class="moduleColor">
            <div class="sm:hidden flex items-center">
                <Icon
                    :name="$getModuleIcon(searchModule.name)"
                    class="w-5 h-5" />
            </div>
            <small class="hidden sm:inline">{{ $t(searchModule.name + '.table.title') }}</small>
        </div>
        <div
            v-else
            class="flex items-center dark:bg-gray-700 bg-gray-300 rounded text-white px-2 py-1 font-bold">
            <div class="sm:hidden flex items-center">
                <Icon
                    name="heroicons:bars-3-bottom-left"
                    class="w-5 h-5" />
            </div>
            <small class="hidden sm:inline">
                {{ $t('globalSearch.everything') }}
            </small>
        </div>

        <div class="relative flex items-center w-3/4 sm:w-2/3 md:w-1/2">
            <SearchPopover
                v-if="!searchModule"
                ref="searchPopover" />
            <CoreInput
                v-model="searchInput"
                class="flex-1"
                :placeholder="searchInputPlaceholder"
                name="global_search_input"
                input-classes="block appearance-none placeholder:text-neutral-300 border-none !bg-transparent rounded h-full w-full border-0 py-0 text-gray-900 dark:text-gray-100 placeholder:text-gray-400 focus:ring-0 sm:text-sm focus:ring-0 focus:outline-none focus:shadow-outline"
                @click="handleInputActive"
                @keydown="handleKeyInput" />

            <Icon
                v-if="searchInput"
                name="heroicons:x-mark"
                class="cursor-pointer inset-y-0 left-0 h-full w-5 text-gray-400"
                aria-hidden="true"
                @click="searchInput = null" />
            <Icon
                v-else
                name="heroicons:magnifying-glass"
                class="pointer-events-none inset-y-0 left-0 h-full w-5 text-gray-400"
                aria-hidden="true" />
        </div>
    </div>
</template>

<script setup>
import {useGlobalSearchStore} from '@/store/globalSearch'
import {useTableFeatures} from '@/composables/useTableFeatures'
import {$getModuleIcon, appModules} from "@/utils/appModules";

const searchPopover = ref(null)
const globalSearchStore = useGlobalSearchStore()
const { t: $t } = useI18n()

const searchModule = computed(() => {
    return appModules[globalSearchStore.currentModule] ?? null
})

const moduleColor = computed(() => {
    if (!searchModule?.value?.color) return 'bg-primary'
    return 'bg-' + searchModule.value.color + '-500'
})

const tableFeatures = computed(() => {
    if (!searchModule?.value?.name) return null
    return useTableFeatures(searchModule.value.name)
})

const searchInput = computed({
    get: () => globalSearchStore.searchInput,
    set: (value) => {
        // set input in store and in table listing
        globalSearchStore.setSearchInput(value)
        const tableListing = tableFeatures?.value?.tableStore?.listing ?? null
        if (tableListing && (tableListing.search || value) && tableListing.search !== value) {
            tableFeatures.value.tableStore.listing.search = value
        }
    }
})

const searchInputPlaceholder = computed(() => {
    if(!searchModule?.value?.name) return $t('globalSearch.defaultPlaceholder')
    return $t('globalSearch.modulePlaceholder', {module: $t(searchModule.value.name + '.table.title')})
})

function handleInputActive() {
    searchPopover?.value?.searchPopoverTrigger?.$el?.click()
}

function handleKeyInput(event) {
    if (event.key === 'Backspace' && !searchInput.value) {
        globalSearchStore.setCurrentModule(null)
        nextTick(() => {
            handleInputActive()
        })
    }
}

/* ********************
 * Handling input value on route change
 * ********************/

watch(() => searchModule.value, (value, prev) => {
    // set search input on table if we are already on the correct page, but only the active module updates
    if (value && !prev) {
        searchInput.value = globalSearchStore.searchInput
    }
})

const route = useRoute();
watch(() => route.path, () => {
    searchInput.value = null
    if (route.query?.search)
        nextTick(() => {
            searchInput.value = route.query?.search ?? null
        })
})
</script>

<style scoped>

</style>