<template>
    <div>
        <!-- Profile dropdown -->
        <Menu
            v-slot="{ open }"
            as="div"
            class="relative">
            <MenuButton class="-m-1.5 flex p-1.5">
                <span class="sr-only">Open user menu</span>
                <div class="flex items-center relative">
                    <Icon
                        name="heroicons:cog-8-tooth"
                        class="h-7 w-7 text-gray-400 dark:text-gray-300" />
                </div>
                <span
                    class="hidden lg:flex lg:items-center">
                    <Icon
                        name="heroicons:chevron-down"
                        class="ml-2 h-4 w-4 text-gray-400 transition-transform duration-150 ease-in-out"
                        :class="{'rotate-180': open}"
                        aria-hidden="true" />
                </span>
            </MenuButton>
            <Transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
                <MenuItems
                    class="absolute right-0 z-10 mt-2.5 origin-top-right rounded-md bg-white dark:bg-gray-700 py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    <NuxtLink :to="useLocalePath()('settings-users')">
                        <BaseMenuItem
                            icon="heroicons:users">
                            {{ $t('menu.users') }}
                        </BaseMenuItem>
                    </NuxtLink>
                    <NuxtLink :to="useLocalePath()('settings-organisations')">
                        <BaseMenuItem
                            icon="heroicons:building-office-2">
                            {{ $t('menu.organisations') }}
                        </BaseMenuItem>
                    </NuxtLink>
                </MenuItems>
            </Transition>
        </Menu>
    </div>
</template>

<script setup>

</script>

<style scoped>

</style>