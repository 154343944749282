<template>
    <div
        class="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 px-4 sm:gap-x-6 sm:px-6 lg:px-8"
        :class="{'shadow-sm' : !layoutStore.isSubheaderOpen}">
        <div class="flex h-16 shrink-0 items-center">
            <NuxtLink to="/">
                <!--<LogoImage class="h-5" />-->
                <span class="h-5 text-lg font-semibold text-gray-900 dark:text-gray-100">
                    EIS.Schmauder
                </span>
            </NuxtLink>
        </div>

        <div class="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
            <div class="flex flex-1 items-center">
                <GlobalSearch v-if="useGlobalSearch" />
            </div>

            <div class="flex items-center gap-x-4 lg:gap-x-6">
                <SettingsMenu v-if="$can('admin', 'system')" />

                <!-- Separator -->
                <div
                    v-if="$can('admin', 'system')"
                    class="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-400"
                    aria-hidden="true" />

                <UserMenu />
            </div>
        </div>
    </div>
</template>

<script setup>
import {useLayoutStore} from "@/store/layout";
import UserMenu from '@/components/layouts/UserMenu/UserMenu'
import SettingsMenu from "@/components/layouts/SettingsMenu/SettingsMenu";

const useGlobalSearch = ref(false)

const layoutStore = useLayoutStore()
</script>
